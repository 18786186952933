export class Image {
    constructor(preview_img) {
        this.preview_img = preview_img
    }

    static fromJSON(item) {
        return new Image(...Object.values(item))
    }

    static fromJSONList(arr) {
        return arr.map((a) => this.fromJSON(a))
    }
}
