<template>
    <q-img :style="styles" :src="src">
        <template v-slot:error>
            <q-img src="@/assets/fail_load.png" />
        </template>
    </q-img>
</template>

<script>
export default {
    name: 'ImageApp',
    props: {
        src: {
            type: String,
            default: '',
        },
        errorImg: {
            type: String,
            default: '',
        },
        height: {
            type: Number,
            default: null,
        },
        width: {
            type: Number,
            default: null,
        },
    },
    computed: {
        styles() {
            let style = {}

            if (this.height) {
                style = Object.assign(style, { height: `${this.height}px` })
            }
            if (this.width) {
                style = Object.assign(style, { width: `${this.width}px` })
            }

            return style
        },
    },
}
</script>

<style></style>
