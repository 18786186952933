import { useServicesStore } from '@/app/store'
import { storeToRefs } from 'pinia'

export function initServiceStore() {
    const serviceStore = useServicesStore()
    const {
        isBusyServiceGroup,
        isBusy: isServiceBusy,
        serviceGroups,
        services,
        sliders,
    } = storeToRefs(serviceStore)
    const {
        serviceSubject,
        getSliders,
        getAllSlider,
        getServiceGroupById,
        getServiceByPlaceId,
        getServiceGroupByPlaceId,
        getAllService,
        getServiceById,
        getServiceGroups,
        getServiceGroupImg,
        getServiceByGroupAndPlaceId,
        toggleBusy: toggleServiceBusy,
        completeSubjectEvent
    } = serviceStore

    return {
        serviceSubject,
        isBusyServiceGroup,
        isServiceBusy,
        serviceGroups,
        services,
        sliders,
        completeSubjectEvent,
        getSliders,
        getAllSlider,
        toggleServiceBusy,
        getServiceGroupById,
        getServiceByPlaceId,
        getServiceGroupByPlaceId,
        getAllService,
        getServiceById,
        getServiceGroups,
        getServiceGroupImg,
        getServiceByGroupAndPlaceId,
    }
}
