import { initAuthStore } from '@/app/helpers/index'
import Repository from '@/app/repositories/repositories'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { useRouter } from 'vue-router'

export default function useAuth(path) {
    const router = useRouter()
    const $q = useQuasar()
    const repo = new Repository()
    const { setAuthState, setUser, setUserLoading } = initAuthStore()

    const signIn = async (json) => {
        let access_token = await repo.signIn(json)
        if (access_token) {
            window.localStorage.setItem('access_token', access_token)
            window.dispatchEvent(
                new StorageEvent('storage', { key: 'access_token' })
            )

            setAuthState()
            initAuth()
            if (path) {
                router.push(path)
            } else {
                router.go()
            }
        } else {
            $q.notify({
                position: 'top-right',
                color: 'red',
                textColor: 'white',
                icon: 'warning',
                message: 'Неверный логин или пароль',
            })
        }
    }

    const initAuth = async () => {
        setUserLoading(true)
        let user = await repo.getUser()
        if (user === null) {
            window.localStorage.removeItem('access_token')
            window.dispatchEvent(
                new StorageEvent('storage', { key: 'access_token' })
            )
        }
        setUser(user)
        setUserLoading(false)
    }

    return {
        signIn,
        initAuth,
    }
}
