<template>
    <q-card flat class="skeleton-tabs">
        <q-card-section class="row q-gutter-md q-pb-none">
            <q-skeleton type="QBtn" v-for="i in items" :key="i" />
        </q-card-section>

        <q-card-section class="gt-xs">
            <div class="row items-start q-gutter-md">
                <q-card class="my-card" v-for="i in items" :key="i">
                    <q-card-section class="q-pa-none" style="width: 190px">
                        <q-skeleton height="190px" square />
                    </q-card-section>
                </q-card>
            </div>
        </q-card-section>
        <div class="q-pa-md lt-sm">
            <q-item style="max-width: 300px">
                <q-item-section avatar>
                    <q-skeleton square type="QAvatar" />
                </q-item-section>

                <q-item-section>
                    <q-item-label caption>
                        <q-skeleton type="rect" width="65%" />
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item style="max-width: 300px">
                <q-item-section avatar>
                    <q-skeleton square type="QAvatar" />
                </q-item-section>

                <q-item-section>
                    <q-item-label caption>
                        <q-skeleton type="rect" width="90%" />
                    </q-item-label>
                </q-item-section>
            </q-item>

            <q-item style="max-width: 300px">
                <q-item-section avatar>
                    <q-skeleton square type="QAvatar" />
                </q-item-section>

                <q-item-section>
                    <q-item-label>
                        <q-skeleton type="rect" width="35%" />
                    </q-item-label>
                </q-item-section>
            </q-item>
        </div>
    </q-card>
</template>

<script>
export default {
    name: 'SkeletonTabs',
    data() {
        return {
            items: Array.from(Array(3).keys()),
        }
    },
}
</script>

<style lang="sass">
.skeleton-tabs
    width: 100%
</style>
