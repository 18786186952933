<template>
    <div class="row items-start">
        <div
            class="q-py-sm q-px-md"
            v-for="service in serviceGroups"
            :key="service.id"
        >
            <CatalogListItemWeb :service="service" />
        </div>
    </div>
</template>

<script>
import CatalogListItemWeb from './CatalogListItemWeb.vue'

export default {
    name: 'CatalogListWeb',
    components: { CatalogListItemWeb },
    props: {
        serviceGroups: {
            type: Array,
            default() {
                return []
            },
        },
    },
}
</script>

<style></style>
