<template>
    <div class="q-ma-xs-none q-ma-sm-md row items-start">
        <q-card flat class="default-card">
            <SkeletonTabs v-if="isBusy || isBusyServiceGroup" />
            <div v-else>
                <q-tabs v-model="tab" no-caps :align="'left'" narrow-indicator>
                    <q-tab
                        v-for="tab in tabs"
                        class="q-pr-sm q-pl-sm"
                        :key="tab.id"
                        :name="tab.id"
                        :label="tab.title"
                        @click="clickOn(tab.id)"
                    />
                </q-tabs>
                <q-card flat class="default-card">
                    <q-tab-panels v-model="tab" animated>
                        <q-tab-panel
                            v-for="tab in tabs"
                            :key="tab.id"
                            :name="tab.id"
                            :label="tab.title"
                            class="q-pl-none q-pr-none"
                            keep-alive
                        >
                            <CatalogListWeb
                                v-if="$q.screen.gt.xs"
                                :serviceGroups="serviceGroups"
                                @go="go"
                            />
                            <CatalogListMobile
                                v-if="$q.screen.lt.sm"
                                :serviceGroups="serviceGroups"
                                @go="go"
                            />
                        </q-tab-panel>
                    </q-tab-panels>
                </q-card>
            </div>
        </q-card>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import SkeletonTabs from '@/app/components/loadings/SkeletonTabs.vue'
import CatalogListWeb from './web/CatalogListWeb.vue'
import CatalogListMobile from './mobile/CatalogListMobile.vue'
import { initPlacesStore, initServiceStore } from '@/app/helpers'
import { useQuasar } from 'quasar'

export default {
    components: { SkeletonTabs, CatalogListWeb, CatalogListMobile },
    name: 'CatalogBlock',
    computed: {
        serviceGroups() {
            return this.getServiceGroupByPlaceId(this.tab)
        },
    },
    setup() {
        const $q = useQuasar()
        const platform = $q.platform

        const routes = useRouter()
        const { places, isBusy } = initPlacesStore()
        const {
            serviceSubject,
            isServiceBusy,
            isBusyServiceGroup,
            getServiceGroupByPlaceId,
            completeSubjectEvent,
        } = initServiceStore()
        const tab = ref(1)
        const tabs = ref(places)
        const selectedTab = 1

        const loadPlaces = async () => {
            let id_place =
                tabs.value != undefined ? tabs?.value[0]?.id : selectedTab
            tab.value = id_place
        }

        const clickOn = (id) => (tab.value = id)

        const go = ({ group_id, place_id }) =>
            routes.push({ name: 'infoService', query: { group_id, place_id } })

        onMounted(async () => {
            loadPlaces()
            serviceSubject.subscribe((result) => {
                let [event, value] = result
                if (event === 'isBusyServiceGroup' && value === false) {
                    loadPlaces()
                    completeSubjectEvent('isBusyServiceGroup')
                }
            })
        })

        return {
            tab,
            tabs,
            isBusy,
            isServiceBusy,
            isBusyServiceGroup,
            getServiceGroupByPlaceId,
            platform,
            clickOn,
            go,
        }
    },
}
</script>

<style lang="sass" scoped>
.my-card
    width: 190px

.my-card-xs
    width: 100%
    height: 190px
    .img
        height: 100px

.my-card-title
    visibility: hidden
    opacity: 0
    transition: .3s

.my-card:hover .my-card-title
    background-color: $my-blue
    visibility: visible
    opacity: 1
    transition: .3s

.default-card
    min-width: 100%
    min-height: 190px
</style>
