export class ServiceGroup {
    constructor({
        id,
        place_id,
        title,
        preview_img
    }) {
        this.id = id,
        this.place_id = place_id,
        this.title = title,
        this.preview_img = preview_img
    }
    
    static fromJSON(item) {
        return new ServiceGroup(item)
    }

    static fromJSONList(arr) {
        return arr.map((a) => this.fromJSON(a))
    }
}