import { http } from '@/services'
import { Service, Place, ServiceGroup, Image, Orders } from '@/app/models'
// import Notify from 'quasar/src/plugins/Notify.js';
import { inject } from 'vue'
import AppNotification from '@/shared/notification'

export default class Repository {
    constructor() {
        this.prefix = '/api/v1'
        this.clientApi = http(this.prefix)
        this.duration = inject('duration')
    }

    async getPlaces() {
        try {
            let result = await this.clientApi.get('/places/')
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Place.fromJSONList(response.data.places)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getAllService() {
        try {
            let result = await this.clientApi.get('/services/')
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Service.fromJSONList(response.data.services)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getServiceByPlaceId(id_place) {
        let result = await this.clientApi.get('/api/service', {
            params: { id: id_place },
        })
        if (!result) return
        if (this.checkError(result.data)) return
        return result.data
    }

    async getServiceById(id_service) {
        try {
            let result = await this.clientApi.get(`/services/${id_service}`)
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Service.fromJSON(response.service)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getPayParams(param) {
        try {
            let result = await this.clientApi.get('/pay/', {
                params: param,
            })
            if (!result) return
            if (this.checkError(result.data)) return
            return result.data
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getTestPayParams(param) {
        try {
            let result = await this.clientApi.get('/pay/test', {
                params: param,
            })
            if (!result) return
            if (this.checkError(result.data)) return
            return result.data
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getServiceGroups() {
        try {
            let result = await this.clientApi.get('/service_group/')
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return ServiceGroup.fromJSONList(response.data.service_groups)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getServiceByGroupAndPlaceId({ group_id, place_id }) {
        try {
            let result = await this.clientApi.get(
                `/service_group/place/${place_id}/service_group/${group_id}/?test=test`
            )
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Service.fromJSONList(response.data.services)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getServiceGroupImg({ group_id, place_id }) {
        try {
            let result = await this.clientApi.get(
                `/preview/place/${place_id}/service_group/${group_id}`
            )
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Image.fromJSON(response.preview)
        } catch (e) {
            console.log('Изображение не найдено')
        }
    }

    async getAllSlider() {
        try {
            let result = await this.clientApi.get(`/slider/`)
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return response.data.slider
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getOrders(visitFormId) {
        try {
            let result = await this.clientApi.get(`/orders/${visitFormId}`)
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Orders.fromJSONList(response.data.orders)
        } catch (e) {
            this.failNotification(e)
            return false
        }
    }

    async getOrderItem(id) {
        try {
            let result = await this.clientApi.get(`/order/${id}`)
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Orders.fromJSON(response.order)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async setOrderComplete(id) {
        try {
            let result = await this.clientApi.get(`/order/completed/${id}`)
            if (!result) return
            return result.data
        } catch (e) {
            this.failNotification(e)
        }
    }

    async getOrderInfo(id) {
        try {
            let result = await this.clientApi.get(`/order/info/${id}`)
            if (!result) return
            if (this.checkError(result.data)) return
            let response = result.data
            return Orders.fromJSON(response.order)
        } catch (e) {
            this.failNotification(e)
        }
    }

    async signIn({ login, password }) {
        try {
            let response = await this.clientApi.post(`/auth/sign-in`, {
                login,
                password,
            })
            return response.data.access_token;
        } catch (e) {
            this.failNotification(e)
        }
    }

    changePassword = async (user) => {
        try {
            const result = await this.clientApi.post(
                `/user/change-password`,
                user
            )
            return result.data.status
        } catch (e) {
            this.failNotification(e)
        }
    }
    
    
    async getUser() {
        try {
            let result = await this.clientApi.get('/auth/get-user')
            return result.data.user
        } catch (e) {
            this.failNotification(e)
        }
    }

    checkError(data) {
        // eslint-disable-next-line
        if (!!data?.message) {
            this.failNotification(data)
            return true
        }

        return false
    }

    failNotification(e) {
        AppNotification({ type: 'negative', message: e.message })
    }
}
