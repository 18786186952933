/**
 * Класс предоставляемых услуг
 */
export class Service {
    constructor({
        id,
        place_id,
        place,
        age_type_id,
        age_type,
        visiting_form_id,
        visiting_form,
        service_group_id,
        service_group,
        service_name_full,
        description_service,
        description_full,
        schedule,
        check_title,
        price,
        item_type_id,
        vat_id,
        vat,
        payment_type,
        preview_img,
        isDisabled,
        created,
        modified,
    }) {
        this.id = id
        this.place_id = place_id
        this.place = place,
        this.age_type_id = age_type_id
        this.age_type = age_type
        this.visiting_form_id = visiting_form_id
        this.visiting_form = visiting_form
        this.service_group_id = service_group_id
        this.service_name_full = service_name_full
        this.service_group = service_group,
        this.description_service = description_service
        this.description_full = description_full
        this.schedule = schedule
        this.check_title = check_title
        this.price = price
        this.item_type_id = item_type_id
        this.vat_id = vat_id
        this.vat = vat
        this.payment_type = payment_type
        this.preview_img = preview_img
        this.isDisabled = isDisabled
        this.created = created
        this.modified = modified
    }

    static fromJSON(item) {
        return new Service(item)
    }

    static fromJSONList(arr) {
        return arr.map((a) => this.fromJSON(a))
    }
}
