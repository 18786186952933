import { defineStore } from 'pinia'

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        isUserLoading: true,
        authStatus: false,
        user: null,
    }),
    getters: {
        isAuth: (state) => () =>  state.authStatus
    },
    actions: {
        setAuthState() {
            this.authStatus = !!window.localStorage.getItem('access_token')
        },
        setUserLoading(value) {
            this.isUserLoading = value
        },
        setUser(user) {
            this.user = user
        }
    },
})
