export default function adminRoutes() {
    return {
        path: '/administrator',
        name: 'admin',
        component: () =>
            import(/* webpackChunkName: "adminclient" */ '../AdminClient.vue'),
        children: [
            {
                path: '',
                name: 'adminHomePage',
                component: () =>
                    import(
                        /* webpackChunkName: "adminhomepage" */ '../pages/HomePage.vue'
                    ),
            },
            {
                path: 'permission',
                name: 'permission',
                component: () =>
                    import(
                        /* webpackChunkName: "permission" */ '../pages/permission/PermissionPage.vue'
                    ),
            },
            {
                path: 'permission/:id',
                name: 'permissionItem',
                component: () =>
                    import(
                        /* webpackChunkName: "permission" */ '../pages/permission/PermissionItemPage.vue'
                    ),
            },
            {
                path: 'users',
                name: 'users',
                component: () =>
                    import(
                        /* webpackChunkName: "users" */ '../pages/users/UsersPage.vue'
                    ),
            },
            {
                path: 'users/:id',
                name: 'userItem',
                component: () =>
                    import(
                        /* webpackChunkName: "userItem" */ '../pages/users/UserItemPage.vue'
                    ),
            },
            {
                path: 'roles',
                name: 'roles',
                component: () =>
                    import(
                        /* webpackChunkName: "roles" */ '../pages/RolesPage.vue'
                    ),
            },
        ],
    }
}
