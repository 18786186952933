<template>
    <q-inner-loading
        :showing="true"
        label="Загрузка..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
    />
</template>

<script>
export default {
    name: "LoadingBlock"
}
</script>

<style></style>
