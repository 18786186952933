import { defineStore } from 'pinia'
import { Subject } from 'rxjs'
import Repository from '@/app/repositories/repositories'

const repo = new Repository()

export const useServicesStore = defineStore('servicesStore', {
    state: () => ({
        sliders: [],
        serviceGroups: [],
        services: [],
        isBusy: false,
        isBusyServiceGroup: false,
        serviceSubject: new Subject(),
    }),
    getters: {
        getServiceGroupById: (state) => (id) =>
            state.serviceGroups?.find((sg) => sg.id == id),
        getServiceByPlaceId: (state) => (id) =>
            state.services?.filter((s) => s.place_id === id) ?? [],
        getServiceGroupByPlaceId: (state) => (id) =>
            state.serviceGroups?.filter((s) => s.place_id === id) ?? [],
        getSliders: (state) => () => state.sliders,
    },
    actions: {
        /**
         * Получение всех слайдеров
         */
        async getAllSlider() {
            if (this.sliders && this.sliders.length > 0) {
                return this.sliders
            }
            this.sliders = await repo.getAllSlider()
        },
        async getAllService() {
            if (this.serviceGroups.length > 0) {
                return
            }
            this.toggleBusy()
            this.services = await repo.getAllService()
            this.toggleBusy()
        },
        async getServiceById(id) {
            this.toggleBusy()
            let result = await repo.getServiceById(id)
            this.toggleBusy()
            return result
        },
        async getServiceGroups() {
            this.isBusyServiceGroup = true
            let result = await repo.getServiceGroups()
            this.serviceGroups = result
            this.isBusyServiceGroup = false
            this.onSubjectEvent(['isBusyServiceGroup', this.isBusyServiceGroup])
        },
        getServiceByGroupAndPlaceId: async ({ group_id, place_id }) =>
            await repo.getServiceByGroupAndPlaceId({ group_id, place_id }),
        getServiceGroupImg: async ({ group_id, place_id }) =>
            await repo.getServiceGroupImg({ group_id, place_id }),
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        onSubjectEvent(value) {
            this.serviceSubject.next(value)
        },
        completeSubjectEvent(event) {
            event
            this.serviceSubject.complete()
        },
    },
})
