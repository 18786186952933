import Notify from 'quasar/src/plugins/Notify.js';

/**
 *
 * @param {string} type 'positive' | 'negative' | 'warning' | 'info' | 'ongoing'
 * @param {string} message
 */
export default function AppNotification({ type = 'positive', message }) {
    Notify.create({
        type: type,
        // color: 'red-5',
        // textColor: 'white',
        // icon: 'warning',
        position: 'top-right',
        message: message,
    })
}
