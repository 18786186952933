<template>
    <div>
        <q-list separator>
            <template v-for="service in serviceGroups" :key="service.id">
                <CatalogListItemMobile :service="service" />
            </template>
        </q-list>
    </div>
</template>

<script>
import { fromBase64ToString } from '@/services'
import CatalogListItemMobile from './CatalogListItemMobile.vue'

export default {
    name: 'CatalogListMobile',
    props: {
        serviceGroups: {
            type: Array,
            default() {
                return []
            },
        },
    },
    setup() {
        return { fromBase64ToString }
    },
    components: { CatalogListItemMobile },
}
</script>

<style></style>
