import { usePlacesStore, useServicesStore } from '@/app/store'
import { onMounted } from 'vue'

export default function usePlacesAndServices() {
    const placeStore = usePlacesStore()
    const serviceStore = useServicesStore()
    const { getAllPlaces } = placeStore
    const { getServiceGroups } = serviceStore

    const loadData = async () => {
        await getAllPlaces()
        await getServiceGroups()
    }

    onMounted(loadData)
}
