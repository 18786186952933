<template>
    <div v-if="images.length > 0" class="q-ma-md">
        <q-carousel
            v-model="slide"
            transition-prev="slide-right"
            transition-next="slide-left"
            swipeable
            animated
            navigation
            arrows
            infinite
            :autoplay="10000"
            height="auto"
        >
            <template v-slot:navigation-icon="{ active, btnProps, onClick }">
                <q-btn
                    class="gt-xs"
                    v-if="active"
                    size="sm"
                    :icon="btnProps.icon"
                    color="blue-grey-10"
                    flat
                    round
                    dense
                    @click="onClick"
                />
                <q-btn
                    class="gt-xs"
                    v-else
                    size="xs"
                    :icon="btnProps.icon"
                    color="white"
                    flat
                    round
                    dense
                    @click="onClick"
                />
            </template>
            <q-carousel-slide
                v-for="(img, i) in images"
                :key="i"
                :draggable="false"
                :name="i"
                class="no-padding"
            >
                <div style="width: 100%; position: relative">
                    <q-img
                        class="picture"
                        :src="img"
                        width="100%"
                        style="max-height: 400px"
                        :ratio="10 / 3.5"
                    />
                </div>
            </q-carousel-slide>
        </q-carousel>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { fromBase64ToString } from '@/services'
import { initServiceStore } from '@/app/helpers'

export default {
    name: 'CarouselBlock',
    computed: {
        images() {
            let res = this.getSliders()
            return res?.map((r) => fromBase64ToString(r.img)) ?? []
        }
    },
    setup() {
        const slide = ref(1)
        const autoplay = ref(10000)
        const { getAllSlider, getSliders } = initServiceStore()

        onMounted(async () => {
            await getAllSlider()
        })

        return {
            slide,
            autoplay,
            getSliders
        }
    },
    mounted() {},
}
</script>

<style></style>
