/**
 * Конвертирует Blob Image (base64) в String
 * @image  blob картинки
 *
 * @return String | null
 */
export const fromBase64ToString = (image) => {
    return image
        ? `data:image/jpeg;base64,${image?.toString('base64')}`
        : 'error'
}
