import { defineStore } from 'pinia'
import Repository from '@/app/repositories/repositories'
import AppNotification from '@/shared/notification'

const repo = new Repository()

export const useOrdersStore = defineStore('ordersStore', {
    state: () => ({
        orders: [],
        isBusy: false,
    }),
    getters: {
        orderList: (state) => () => {
            return state.orders
        },
        searchOrder: (state) => (str) => {
            if (str === '') return state.orders

            str = str.toLowerCase()
            let result = state.orders
                .filter((s) => !!s?.clientid)
                .filter((s) => {
                    let regNum = [...str.matchAll(/[\d]/g)].join('')
                    let num = !regNum ? null : regNum
                    if (
                        s?.clientid.toLowerCase().includes(str) ||
                        s?.client_email?.toLowerCase().includes(str) ||
                        s?.check_title?.toLowerCase().includes(str) ||
                        s?.client_phone?.replace(/[^\d]+/g, '').includes(num) ||
                        s?.order_id.replace(/[^\d]+/g, '').includes(num)
                    )
                        return s
                })
            return result
        },
    },
    actions: {
        async updateOrders(visitFormId = 1) {
            this.toggleBusy()
            this.orders = await repo.getOrders(visitFormId) ?? []
            this.toggleBusy()
        },
        async getOrders(visitFormId = 1) {
            this.toggleBusy()
            this.updateOrders(visitFormId)
            this.toggleBusy()
        },
        async getOrder(order_id) {
            let result = await repo.getOrderItem(order_id)
            return result
        },
        async getOrderInfo(paymentId) {
            let result = await repo.getOrderInfo(paymentId)
            return result
        },
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        async setOrderComplete(id) {
            let result = await repo.setOrderComplete(id)
            if (result.status) {
                AppNotification({
                    message: result.message
                })
            }
            this.updateOrders()
        },
    },
})
