<template>
  <div>
    <Carousel />
    <Catalog />
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/app/components/Carousel.vue';
import Catalog from '@/app/components/catalog/Catalog.vue';

export default {
    name: "HomeView",
    components: { Carousel, Catalog },
    setup() {
    }
}
</script>
