<template>
    <router-view />
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted } from 'vue'
import { initAuthStore } from '@/app/helpers/index'
import useAuth from '@/composables/useAuth'
import usePlacesAndServices from '@/app/pages/hooks/usePlacesAndServices'

export default {
    name: 'App',
    setup() {
        const { setAuthState } = initAuthStore()
        const { initAuth } = useAuth()
        usePlacesAndServices()

        onBeforeMount(initAuth)

        onMounted(() => {
            setAuthState()
            window.addEventListener('storage', onStorageUpdate)
        })

        onUnmounted(() => {
            window.removeEventListener('storage', onStorageUpdate)
        })

        const onStorageUpdate = (event) => {
            if (event.key === 'access_token') {
                setAuthState()
            }
        }
    },
}
</script>

<style></style>
