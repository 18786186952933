import HomeView from '../pages/HomeView.vue'
import InfoServiceView from '../pages/InfoServiceView.vue'

export default function appRoutes() {
    return {
        path: '/',
        name: 'appClient',
        component: () =>
            import(/* webpackChunkName: "appClient" */ '../AppClient.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: HomeView,
            },
            {
                path: '/info-service',
                name: 'infoService',
                component: InfoServiceView,
            },
            {
                path: '/about',
                name: 'about',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../pages/AboutView.vue'
                    ),
            },
            {
                path: '/pay/order',
                name: 'payOrder',
                component: () =>
                    import(
                        /* webpackChunkName: "payOrder" */ '../pages/PayOrderView.vue'
                    ),
            },
            {
                path: '/orders',
                name: 'Orders',
                component: () =>
                    import(
                        /* webpackChunkName: "orders" */ '../pages/orders/OrderListView.vue'
                    ),
            },
            {
                path: '/order/:id',
                name: 'Order',
                component: () =>
                    import(
                        /* webpackChunkName: "order" */ '../pages/orders/OrderItemView.vue'
                    ),
            },
            {
                path: '/personal-data',
                name: 'PersonalData',
                component: () =>
                    import(
                        /* webpackChunkName: "personalData" */ '../pages/info/PersonalDataView.vue'
                    ),
            },
            {
                path: '/approved-personal-data',
                name: 'ApprovedPersonalData',
                component: () =>
                    import(
                        /* webpackChunkName: "approvedPersonalData" */ '../pages/info/ApprovedPersonalDataView.vue'
                    ),
            },
            {
                path: '/common',
                name: 'TermsOfSale',
                component: () =>
                    import(
                        /* webpackChunkName: "termsOfSale" */ '../pages/info/CommonView.vue'
                    ),
            },
            {
                path: '/update-page',
                name: 'UpdatePage',
                component: () =>
                    import(
                        /* webpackChunkName: "updatePage" */ '../pages/error/UpdatePageView.vue'
                    ),
            },
            {
                path: '/cashback',
                name: 'Cashback',
                component: () =>
                    import(
                        /* webpackChunkName: "cashback" */ '../pages/info/CashbackView.vue'
                    ),
            },
        ],
    }
}
