import { computed } from 'vue'
import useServiceByGroupId from './serviceByGroupId'
import usePlacesAndServices from './usePlacesAndServices'
import { initPlacesStore } from '@/app/helpers'

export default function useInfoService() {
    usePlacesAndServices()
    const {isBusy, services, imgSrc, serviceGroup, place_id} = useServiceByGroupId()
    
    const { getPlaceById } = initPlacesStore()

    const onComplete = (value) => {
        window.location.replace(value)
    }

    const ageType = {
        1: 'Услуги для детей',
        2: 'Услуги для взрослых',
    }

    const place = computed(() => {
        return getPlaceById(place_id) ?? ''
    })

    return {
        isBusy,
        services,
        place,
        serviceGroup,
        onComplete,
        ageType,
        imgSrc,
    }
}
