<template>
    <q-item clickable v-ripple @click="go">
        <q-item-section avatar>
            <q-avatar square="">
                <ImageApp :height="40" :src="imgSrc" />
            </q-avatar>
        </q-item-section>
        <q-item-section>{{ service.title }}</q-item-section>
    </q-item>
</template>

<script>
import { ServiceGroup } from '@/app/models'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { fromBase64ToString } from '@/services'
import useLoadingImage from '../useLoadingImage'
import ImageApp from '@/shared/ui/ImageApp.vue'

export default {
    name: 'CatalogListItemMobile',
    components: { ImageApp },
    props: {
        service: {
            type: ServiceGroup,
            default() {
                return {}
            },
        },
    },
    setup(props) {
        const routes = useRouter()
        const imgSrc = ref()
        const { loadImg } = useLoadingImage()

        const imageSrc = async () => {
            let res = await loadImg({
                group_id: props.service.id,
                place_id: props.service.place_id,
            })
            imgSrc.value = res ? fromBase64ToString(res?.preview_img ?? '') : 'undefined'
        }

        const go = () =>
            routes.push({
                name: 'infoService',
                query: {
                    group_id: props.service.id,
                    place_id: props.service.place_id,
                },
            })

        onMounted(async () => {
            await imageSrc()
        })

        return {
            go,
            imgSrc,
        }
    },
}
</script>

<style></style>
