import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from 'pinia'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import durationPlugin from './plugins/duration'

const pinia = createPinia()
const app = createApp(App)

app.use(Quasar, quasarUserOptions)
    .use(router)
    .use(pinia)
    .use(durationPlugin)
    .mount('#app')

export default app
