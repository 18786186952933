import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { fromBase64ToString } from '@/services'
import { initServiceStore } from '@/app/helpers'

export default function useServiceByGroupId() {
    const route = useRoute()
    const { group_id, place_id } = route.query

    const services = ref(null)
    const imgSrc = ref()

    const {
        isBusy,
        getServiceByGroupAndPlaceId,
        getServiceGroupById,
        getServiceGroupImg,
    } = initServiceStore()

    const serviceGroup = computed(() => {
        return getServiceGroupById(group_id) ?? ''
    })

    const loadServices = async () => {
        let result = await getServiceByGroupAndPlaceId({
            group_id,
            place_id,
        })
        services.value = groupBy(result, 'age_type_id')
    }

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            )
            return result
        }, {})
    }

    const imageSrc = async () => {
        let res = await getServiceGroupImg({
            group_id,
            place_id,
        })
        imgSrc.value = res
            ? fromBase64ToString(res?.preview_img ?? '')
            : 'undefined'
    }

    const loadData = async () => {
        await loadServices()
        imageSrc()
    }

    onMounted(loadData)

    return {
        isBusy,
        serviceGroup,
        services,
        imgSrc,
        place_id,
    }
}
