export class Orders {
    constructor({
        id,
        order_id,
        status,
        description,
        check_title,
        payment_data,
        cart,
        orderid,
        callback_payment_date,
        clientid,
        client_email,
        client_phone,
        callback_payment_id,
        pay_amount,
        receipt_url,
    }) {
        this.id = id
        this.order_id = order_id
        this.orderid = orderid
        this.status = status
        this.description = this.parser(description)
        this.check_title = check_title
        this.payment_data = payment_data
        this.cart = cart
        this.callback_payment_date = callback_payment_date
        this.clientid = clientid
        this.client_email = client_email
        this.client_phone = client_phone
        this.callback_payment_id = callback_payment_id
        this.pay_amount = pay_amount
        this.receipt_url = receipt_url
    }

    parser(str) {
        if (this.checkObject(str)) return str

        return this.isJsonString(str) ? JSON.parse(str) : null
    }

    checkObject(obj) {
        return typeof obj === 'object'
    }

    isJsonString(str) {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    static fromJSON(item) {
        return new Orders(item)
    }

    static fromJSONList(arr) {
        return arr.map((a) => this.fromJSON(a))
    }
}
