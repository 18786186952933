import { useAuthStore } from '@/app/store'
import { storeToRefs } from 'pinia'

export function initAuthStore() {
    const authStore = useAuthStore()
    const { authStatus, user, isUserLoading } = storeToRefs(authStore)
    const { isAuth, setAuthState, setUser, setUserLoading } = authStore
    return {
        user,
        authStatus,
        isUserLoading,
        isAuth,
        setAuthState,
        setUser,
        setUserLoading,
    }
}
