import { createRouter, createWebHistory } from 'vue-router'
import adminRoutes from '@/admin/routes/admin-route'
import appRoutes from '@/app/routes/app-route'

const appRoute = appRoutes()
const adminRoute = adminRoutes()
const routes = [appRoute, adminRoute]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
