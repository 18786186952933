export class Place {
    constructor(id, title) {
        this.id = id
        this.title = title
    }

    static fromJSON(item) {
        return new Place(...Object.values(item))
    }

    static fromJSONList(arr) {
        return arr.map(a => this.fromJSON(a))
    }
}