export default {
    // eslint-disable-next-line
    install: (app, options) => {
        const duration = (delay = 1000) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve()
                }, delay)
            })
        }
        app.config.globalProperties.$duration = duration

        app.provide('duration', duration)
    },
}
