import { ref } from 'vue'

export default function userDateSelectOption() {
    const selectOption = ref([])
    const visibleCountMonth = window.env.visibleCountMonth

    const monthName = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ]
    for (const month in Array.from(Array(visibleCountMonth).keys())) {
        const today = new Date()
        const currentMonth = today.getMonth()
        const nextDate = new Date(
            today.setMonth(currentMonth + parseFloat(month), 1)
        )
        const nextMonth = nextDate.getMonth()
        const nextYear = nextDate.getFullYear()
        selectOption.value.push({
            value: (nextDate.getMonth() + 1).toString(),
            label: `${monthName[nextMonth]} ${nextYear}`,
        })
    }
    
    return { selectOption }
}
