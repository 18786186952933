import { useOrdersStore } from '@/app/store'
import { storeToRefs } from 'pinia'

export function initOrdersStore() {
    const ordersStore = useOrdersStore()
    const { orders, isBusy } = storeToRefs(ordersStore)
    const {
        orderList,
        searchOrder,
        updateOrders,
        getOrder,
        getOrders,
        getOrderInfo,
        toggleBusy,
        setOrderComplete
    } = ordersStore
    return {
        orders,
        isBusy,
        updateOrders,
        searchOrder,
        orderList,
        getOrder,
        getOrders,
        toggleBusy,
        getOrderInfo,
        setOrderComplete
    }
}
