import axios from 'axios'
import router from '@/router'
import AppNotification from '@/shared/notification'
class Http {
    constructor(prefix = '') {
        this.http = axios.create({
            baseURL: process.env.VUE_APP_AXIOS_URL + prefix,
            // withCredentials: true,
            timeout: 90000,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers':
                    'X-Requested-With, Content-Type',
            },
        })

        this.interceptor()
    }

    interceptor() {
        this.http.interceptors.request.use(
            (config) => {
                if (window.localStorage.access_token) {
                    config.headers.Authorization = `Bearer ${window.localStorage.access_token}`
                }
                //Надо возвращать конфиг после его модификации
                return config
            },
            (error) => {
                //Этот блок кода срабатывает только тогда, когда ошибка отправки запроса с фронта
                console.log(error)
            }
        )

        //start response
        this.http.interceptors.response.use(
            (config) => {
                if (window.localStorage.getItem('access_token')) {
                    config.headers.Authorization = `Bearer ${window.localStorage.access_token}`
                }

                return config
            },
            (error) => {
                //Этот блок кода срабатывает когда прилетает ошибка с бэка
                console.log(error)
                if (error.response?.data.message === 'Token has expired') {
                    this.removeLocalStorage('access_token')
                    router.push('/')
                    //TODO: Надо добавить refreshToken
                }

                if (error.response.status === 401) {
                    this.removeLocalStorage('access_token')
                    AppNotification({
                        type: 'negative',
                        message: error.response.data.message,
                    })
                    router.push('/')
                }

                if (error.response.status === 403) {
                    AppNotification({
                        type: 'negative',
                        message: error.response.data.message,
                    })
                }
            }
        )
    }

    removeLocalStorage(key) {
        window.localStorage.removeItem(key)
        window.dispatchEvent(new StorageEvent('storage', { key: key }))
    }

    get(url, params) {
        try {
            return this.http.get(url, params)
        } catch (e) {
            console.log(e)
        }
    }

    post(url, data) {
        return this.http.post(url, {
            data: JSON.stringify(data),
        })
    }
}

export const http = (prefix = '') => new Http(prefix)
