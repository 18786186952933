import { useConfigStore } from '@/app/store'
// import { storeToRefs } from 'pinia'

export function initConfigStore() {
    const configStore = useConfigStore()
    const {
        isPayDisabled,
        infoMessage
    } = configStore
    return {
        isPayDisabled,
        infoMessage
    }
}
