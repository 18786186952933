import { usePlacesStore } from '@/app/store'
import { storeToRefs } from 'pinia'

export function initPlacesStore() {
    const placeStore = usePlacesStore()
    const { places, isBusy } = storeToRefs(placeStore)
    const {
        getPlaceById,
        getAllPlaces,
        toggleBusy,
        
    } = placeStore
    return {
        places,
        isBusy,
        getPlaceById,
        getAllPlaces,
        toggleBusy,
    }
}
