<template>
    <div>
        <q-card flat class="q-mb-md">
            <Loading v-if="isBusy" />
            <div v-if="services !== null">
                <q-card-section>
                    <q-item class="q-pl-none">
                        <q-item-section top avatar>
                            <q-img
                                :src="imgSrc"
                                :ratio="1"
                                spinner-color="primary"
                                style="height: 80px; width: 80px"
                            >
                                <template v-slot:error>
                                    <q-img
                                        src="@/assets/fail_load.png"
                                        style="height: 80px; width: 80px"
                                    />
                                </template>
                            </q-img>
                        </q-item-section>
                        <q-item-section>
                            <div class="text-h6">
                                {{ place.title }} {{ serviceGroup.title }}
                            </div>
                        </q-item-section>
                    </q-item>
                </q-card-section>
                <q-separator />
                <q-card-section
                    v-for="(items, key) in services"
                    :key="key"
                    class="q-pb-none q-pt-sm q-pr-none"
                >
                    <div class="text-bold">{{ ageType[key] }}</div>
                    <q-separator class="q-mt-sm q-mb-sm" />

                    <q-list dense class="">
                        <q-card flat v-for="service in items" :key="service.id">
                            <q-card-section class="no-padding">
                                <q-item class="q-pl-none q-pt-none">
                                    <q-item-section
                                        class="text-right"
                                        top
                                        avatar
                                    >
                                        <DialogOncePay
                                            :service="service"
                                            @on-complete="onComplete"
                                        />
                                    </q-item-section>
                                    <q-item-section top>
                                        <div>
                                            <span class="text-bold">
                                                {{ service.service_name_full }}
                                            </span>
                                        </div>
                                        <div>
                                            {{ service.description_full }}
                                        </div>
                                        <div>
                                            {{ service.description_service }}
                                        </div>
                                    </q-item-section>
                                </q-item>
                            </q-card-section>
                        </q-card>
                    </q-list>
                    <q-separator />
                </q-card-section>
            </div>
        </q-card>
    </div>
</template>

<script>
import Loading from '@/app/components/loadings/Loading.vue'
import DialogOncePay from '@/app/components/dialogs/DialogOncePay.vue'
import useInfoService from './hooks/infoService'

export default {
    name: 'InfoServiceView',
    components: { Loading, DialogOncePay },
    setup() {
        const {
            isBusy,
            imgSrc,
            services,
            place,
            serviceGroup,
            ageType,
            onComplete,
        } = useInfoService()

        return {
            isBusy,
            imgSrc,
            services,
            place,
            serviceGroup,
            ageType,
            onComplete,
        }
    },
}
</script>

<style></style>
