import { initServiceStore } from '@/app/helpers'

export default function useLoadingImage() {
    const { getServiceGroupImg } = initServiceStore()

    const loadImg = async ({ group_id, place_id }) => {
        let res = await getServiceGroupImg({
            group_id,
            place_id,
        })
        return res
    }
    return { loadImg }
}
