import { defineStore } from 'pinia'
import Repository from '@/app/repositories/repositories'

const repo = new Repository()

export const usePlacesStore = defineStore('placesStore', {
    state: () => ({
        places: [],
        isBusy: false,
    }),
    getters: {
        getPlaceById: (state) => (id) => state.places.find(p => p.id == id),
    },
    actions: {
        async getAllPlaces() {
            let result = await repo.getPlaces()
            this.places = result
        },
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
    },
})
